import React from 'react';
import {Button} from 'react-bootstrap';
import {IPicture} from '../../../model/shared/IPicture';
import {downloadFileAPI} from '../../../utils';
import {MESSAGE_DOWNLOAD_FILES_URL} from '../../../utils/endpoints';
import {WarningToast} from '../../../utils/toasters';
import {downloadFile, getCorrectImageThumbnail, getPptxUrl} from '../../functions/Functions';
import {CustomImageHandler} from '../CustomImageHandler';
import {Fancybox} from '../Fancybox';
import { t } from 'i18next';
import FilePlaceholder from '../placeholder/FilePlaceholder';

interface IFileMessage {
    files?: IPicture[];
    isMessageBubble?: boolean;
    messageId?: number;
    isOfferComment?: boolean;
}

export const FileMessage = ({files, messageId, isMessageBubble = false, isOfferComment = false}: IFileMessage) => {
    const url = `${MESSAGE_DOWNLOAD_FILES_URL}/${messageId}`;

    const downloadWithoutUrl = () => {
        if (!!files?.length) {
            for (const file of files) {
                downloadFile(file?.path, file?.originalName);
            }
        } else {
            WarningToast('No uploaded files');
        }
    };

    return (
        <>
            {files?.map(file => {
                const isImage = file?.mime?.includes('image')
                const isPdf = file?.path?.includes('.pdf');
                return (
                    <div className={`col-md${isMessageBubble ? `-12 my-3` : `-6 col-6`} mt-3`}
                         key={file?.id}>
                        <Fancybox options={{infinite: false}}>
                            <div
                                data-fancybox={`message-fancybox-${file?.id}`}
                                data-type="iframe"
                                className="cursor-pointer"
                                data-src={!isImage ? (isPdf ? file?.path : getPptxUrl(file?.path)) : (file?.externalLink || file?.path || '/icons/filePlaceholder.png')}
                            >
                                {isImage ? (
                                    <CustomImageHandler
                                        altTag="File placeholder"
                                        classes="w-100 default-radius"
                                        style={{aspectRatio: '4/3', objectFit: 'cover'}}
                                        photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.title ?? '', file?.thumbPath ?? '', file?.path)}
                                        placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.title ?? '', file?.thumbPath ?? '', file?.path)}
                                    />
                                ) : (
                                    <FilePlaceholder file={file}  
                                        classContent='cursor-pointer image-preview-card-height bg-white default-radius' />
                                )}
                            </div>
                        </Fancybox>
                    </div>
                );
            })}
            <div className="col-12 my-3 px-0">
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        isOfferComment ?
                            downloadWithoutUrl() :
                            downloadFileAPI(url);
                    }}>
                    {t('general.download')} {files && files?.length > 1 ? 'all' : ''}
                </Button>
            </div>
        </>
    );
};
