import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../shared/components/Fancybox';
import TextEditor from '../../../../../shared/components/TextEditor';
import {getCorrectImageThumbnail} from '../../../../../shared/functions/Functions';
import {setDescription} from '../../../../../store/influencer/offerInfluencerSlice';
import {IAllStates} from '../../../../../store/rootReducer';
import DOMPurify from 'dompurify';
import FilePlaceholder from '../../../../../shared/components/placeholder/FilePlaceholder';

export function ContentTab() {
    const {
        campaign,
        initialOffer,
        requiredFieldChangeColor
    } = useSelector((state: IAllStates) => state.offerInfluencer);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const changeTextFieldHandler = (value: string) => {
        // const editorValue = value.replace(/<[^\/>][^>]*><\/[^>]+>/g, '');
        dispatch(setDescription(value));
    };
    return (
        <Row>
            <Col xs={12} className="mb-2">
                <h6 className="text-muted">{t('influencer.offer_management.modal.content_briefing')}</h6>
                <p>
                    {t('influencer.offer_management.modal.content_briefing_description')}
                </p>
                {campaign?.offers[0]?.description ?
                    <div className="remove-html-styling"
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(campaign?.offers[0]?.description ?? '-')}}/>
                    :
                    <TextEditor classes={'text-editor-height'}
                                state={initialOffer.description}
                                placeholder={t('influencer.offer_management.modal.content_briefing_placeholder')}
                                hasError={requiredFieldChangeColor}
                                changeTextFieldHandler={(value) => changeTextFieldHandler(value)}/>
                }

            </Col>
            <Col className="mt-1 mt-md-4" xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.campaign_description')}</h6>
                <div className="mb-2 remove-html-styling"
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(campaign?.adDescription ?? '-')}}/>
            </Col>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.relevant_hash')}</h6>
                <div className="d-flex flex-wrap">
                    <p>{campaign?.hashtags ?? '-'}</p>
                </div>
            </Col>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.tagging')}</h6>
                <div className="d-flex flex-wrap">
                    <p>{campaign?.profileTags ?? '-'}</p>
                </div>
            </Col>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.additional_briefing_files')}</h6>
            </Col>
            <Col xs={12}>
                <Row>
                    {!!campaign?.campaignImages?.length ? campaign?.campaignImages
                        ?.filter(image => image?.purpose === 'style_mood_image')
                        ?.map(moonBoard => {
                            const isPDF = moonBoard.mime === 'application/pdf';

                            return(
                                <div className="col-md-3 my-2 pl-0" key={moonBoard?.id}>
                                    <Fancybox options={{infinite: false}}>
                                        <div
                                            data-fancybox="gallery"
                                            className="cursor-pointer w-100"
                                            data-src={moonBoard?.externalLink ?? moonBoard?.path ?? '/icons/filePlaceholder.png'}
                                        >
                                            {isPDF ? (
                                                <FilePlaceholder file={moonBoard} styleContent={{height: 200}} />
                                            ) : (
                                                <CustomImageHandler
                                                    classes="w-100 img-cover box-shadow default-radius"
                                                    style={{height: 200}}
                                                    placeholder={getCorrectImageThumbnail(!!moonBoard?.externalLink, moonBoard?.name ?? '', moonBoard?.thumbPath ?? '', moonBoard?.path)}
                                                    photoPath={getCorrectImageThumbnail(!!moonBoard?.externalLink, moonBoard?.name ?? '', moonBoard?.thumbPath ?? '', moonBoard?.path)}
                                                    altTag={moonBoard?.title}/>
                                            )}
                                        </div>
                                    </Fancybox>
                                </div>
                            );
                        }) : '-'}
                </Row>
            </Col>
            <Col xs={12} className="mb-2">
                <h6 className="text-muted">{t('influencer.offer_management.modal.inspiration_links')}</h6>
                <a href={campaign?.url} target="_blank" rel="noreferrer">
                    {campaign?.url ?? '-'}
                </a>
            </Col>
        </Row>
    );
}
