import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {BiDownload} from 'react-icons/bi';
import {FaRegTrashAlt} from 'react-icons/fa';
import {IPicture} from '../../model/shared/IPicture';
import {getPptxUrl} from '../functions/Functions';
import {ConfirmationModal} from './alert/ConfirmationModal';
import {CustomImageHandler} from './CustomImageHandler';
import {Fancybox} from './Fancybox';
import FilePlaceholder, { getFileType } from './placeholder/FilePlaceholder';

interface IUploadImageProps {
    saveFile: React.Dispatch<React.SetStateAction<Blob[] | MediaSource[] | undefined>>;
    previewImage?: string | IPicture[];
    acceptedFormats?: string;
    isMultiple?: boolean;
    isDeleteHidden?: boolean;
    isPreviewArray?: boolean;
    isSmall?: boolean;
    isFullHeight?: boolean;
    disabled?: boolean;
    error?: boolean;
    classes?: string;
    numberOfFiles?: number;
    maxFiles?: number;
    maxSize?: number;
    handleDownloadImage?: (id: number) => void;
    deleteSelectedImage?: (id: number) => void;
}

const fileMimes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

export function UploadImage({
                                saveFile,
                                previewImage,
                                handleDownloadImage,
                                isMultiple,
                                deleteSelectedImage,
                                acceptedFormats,
                                numberOfFiles = 0,
                                maxFiles = 10,
                                classes,
                                isDeleteHidden = false,
                                disabled = false,
                                error = false,
                                isSmall = false,
                                isFullHeight = false,
                                isPreviewArray = true,
                                maxSize = 10485760 // 10MB
                            }: IUploadImageProps) {
    const [errors, setErrors] = useState('');
    const [files, setFiles] = useState<Blob[] | MediaSource[]>([]);
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (!!fileRejections?.length) {
            fileRejections.forEach((file: any) => {
                file?.errors?.forEach((err: { code: string, message: string }) => {
                    setErrors(t(`general.fileUpload.${err.code}`, {
                        maxSize: (maxSize / 1024 / 1024).toFixed(), // get value in MB from B
                        maxFiles: maxFiles,
                    }));
                });
            });
        } else {
            setErrors('');
        }
        setFiles(acceptedFiles.map((file: Blob | MediaSource) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        })));
        isMultiple ? saveFile(acceptedFiles) : saveFile(acceptedFiles[0]);
        // eslint-disable-next-line
    }, []);

    const [selectedImage, setSelectedImage] = useState<IPicture>();

    const {getRootProps, getInputProps} = useDropzone({
        accept: acceptedFormats ? acceptedFormats : 'image/jpeg, image/png, image/jpg',
        multiple: isMultiple,
        onDrop, disabled,
        maxSize: maxSize,
        maxFiles: maxFiles,
    });

    const thumbs = files?.map((file: any) => {
        const isImage = file?.type?.includes('image');
        const isVideo = file?.type?.includes('video');
        const isPdf = file?.type?.includes('pdf');
        const isPptx = file.type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation');
        const getCorrectPreviewURL = () => {
            if (isVideo) {
                return '/tempAssets/video-placeholder.webp'
            }
            if (isImage) {
                return file.preview;
            }
            if (isPptx) {
                return 'https://images.freeimages.com/fic/images/icons/2795/office_2013_hd/2000/powerpoint.png';
            }
            return 'https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/thumbnails/image/file.jpg';
        }
        return (
            <div className="thumb" key={file.name}>
                <div className="thumb-inner">
                    {(isPdf || isPptx) ? (
                        <i className={`${getFileType(file.name).iconClass} font-50 d-flex`}/>
                    ) : (
                        <img
                            alt="preview-profile-img"
                            src={getCorrectPreviewURL()}
                        />
                    )}
                </div>
            </div>
        );
    });

    const getFormatsDesc = (formats: string) => {
        const list = formats.split(',');
        return list.map(formatItem => {
            if (formatItem.trim() === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                return 'application/powerpoint';
            }

            if (formatItem.trim() === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                return 'application/docx';
            }
            return formatItem;
        }).join(', ');
    };

    useEffect(() => {
        if (!numberOfFiles) {
            setFiles([]);
        }
    }, [numberOfFiles]);

    useEffect(() => {
        return () => files?.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className={`container ${isFullHeight ? 'h-100' : ''} ${classes}`}>
            <div {...getRootProps({className: `dropzone h-100 ${isSmall ? 'min-140px' : ''} ${error ? 'error' : ''}`})}>
                <input {...getInputProps()} />
                {errors && (
                    <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>
                        {errors}
                    </p>
                )}
                {(files.length > 0) ?
                    (<aside className="thumbs-container">{thumbs}</aside>) :
                    (typeof (previewImage) === 'string') ?
                        <div className="thumb">
                            <div className="thumb-inner">
                                <CustomImageHandler photoPath={previewImage}
                                                    altTag="User Profile"/></div>
                        </div>
                        :
                        <div className="text-center">
                            <img src="/icons/upload-icon.png" alt="user-upload"/>
                            <p className="m-0">{t('general.upload_photo')}</p>
                        </div>
                }
            </div>
            {!!acceptedFormats?.length && (
                <p className="text-muted mt-1">({getFormatsDesc(acceptedFormats)}) formats only</p>
            )}
            {isPreviewArray ?
                <div className="row">
                    {(previewImage as IPicture[])?.map(prevImage => {               
                        const hasIcon = fileMimes.includes(prevImage.mime);
                        // const isPowerpoint = prevImage.path.includes('.ppt');
                        const isPDF = prevImage?.mime === 'application/pdf';

                        return (
                            <div key={prevImage?.id} className="col-md-4 pl-0 my-1 position-relative  mt-2">
                                <div className="default-radius">
                                    <Fancybox options={{infinite: false,}}>
                                        <div
                                            data-type="iframe"
                                            data-fancybox={`gallery-${previewImage?.length}`}
                                            data-src={isPDF ? prevImage?.path : hasIcon ? getPptxUrl(prevImage?.path) : prevImage?.path ? prevImage?.path : '/icons/filePlaceholder.png'}
                                        >
                                            {hasIcon ? (
                                                <FilePlaceholder file={prevImage}
                                                    classContent='cursor-pointer image-preview-card-height bg-white' />
                                            ) : (
                                                <CustomImageHandler photoPath={prevImage?.path}
                                                                    thumbnailPath={prevImage?.thumbnail}
                                                                    classes="w-100 cursor-pointer img-cover default-radius image-preview-card-height"
                                                                    altTag={prevImage?.title}/>
                                            )}
                                        </div>
                                    </Fancybox>
                                    {!isDeleteHidden &&
                                        <a href="#1" className="cursor-pointer position-absolute"
                                           style={{top: '-5px', right: 10}} onClick={() => {
                                            setShowModal(true);
                                            setSelectedImage(prevImage);
                                        }}>
                                            <div className="top-right-icon upload-icon">
                                                <FaRegTrashAlt size={15} className="text-danger"/>
                                            </div>
                                        </a>
                                    }
                                    {handleDownloadImage && <a href="#2" className="cursor-pointer position-absolute"
                                                               style={{top: '-5px', right: isDeleteHidden ? 10 : 50}}
                                                               onClick={() => handleDownloadImage(prevImage?.id)}>
                                        <div className="top-right-icon upload-icon">
                                            <BiDownload size={15} className="text-primary"/>
                                        </div>

                                    </a>}
                                </div>
                            </div>
                        )
                    })}
                </div> : null}
            <ConfirmationModal
                title={t('brand.campaign.briefing.step2.delete_file')}
                description={`${t('brand.campaign.briefing.step2.confirm_message')}`}
                action={() => deleteSelectedImage && deleteSelectedImage(selectedImage?.id as number)}
                show={showModal} closeModal={() => setShowModal(false)}
            />
        </section>
    );
}
