import React, { CSSProperties } from 'react'
import { BiDownload } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';

interface IPdfPlaceholder {
    styleContent?: CSSProperties;
    classContent?: string;
    classIcon?: string;
    isLargeIcon?: boolean,
    file: any;
    hideText?: boolean;
    numberFiles?: number
    handleDownload?: (...args: any) => void; 
}

export const getFileType = (fileName: string) => {
  const extension = (fileName?.split('.').pop() || '').toLowerCase();

  switch (extension) {
    case 'pdf':
      return { type: 'pdf', iconClass: 'fi fi-rr-file-pdf text-danger', imgSrc: '' };
    case 'doc':
    case 'docx':
      return { type: 'word', iconClass: 'fi fi-rr-file-word text-primary', imgSrc: '' };
    case 'xls':
    case 'xlsx':
      return { type: 'excel', iconClass: 'fi fi-rr-file-excel text-success', imgSrc: '' };
    case 'ppt':
    case 'pptx':
      return { type: 'powerpoint', iconClass: 'fi fi-rr-file-powerpoint text-warning', imgSrc: '' };
    default:
      return { type: 'other', iconClass: 'fi fi-rr-file text-muted', imgSrc: '' };
  }
};

export default function FilePlaceholder({
  classIcon, 
  classContent, 
  styleContent, 
  isLargeIcon, 
  handleDownload, 
  file,
  hideText = false,
  numberFiles,
}: IPdfPlaceholder) {
  return (
    <>
      <div className={`box-shadow d-flex align-items-center justify-content-center ${classContent ?? ''}`}
          style={styleContent}
      >
        {isLargeIcon ? (
          <img src={getFileType(file?.name).imgSrc} alt={file?.name} className={`${classIcon}`}/>
        ) : (
          <i className={`${getFileType(file?.name).iconClass} d-flex ${classIcon ?? 'font-50'}`}/>
        )}

        {handleDownload && (
          <a href="#2" className="cursor-pointer position-absolute"
                                    style={{top: '-5px', right: 20}}
                                    onClick={() => handleDownload?.()}>
              <div className="top-right-icon upload-icon">
                  <BiDownload size={15} className="text-primary"/>
              </div>
          </a>
        )}
      </div>
      {!hideText && (
        <Row className='align-items-center justify-content-center flex-nowrap my-1'>
          <Col xs={numberFiles && numberFiles > 1 ? 'auto' : 12}>
            <p className='text-muted text-center mb-0'>
              {file?.title}
              {file?.originalName}
            </p>
          </Col>

          {numberFiles && numberFiles > 1 && (
            <Col xs='auto' className='pt-2 px-0'>
              <div className="icon-round bg-dark font-weight-semi-bold"
                        style={{width: 33}}>
                    + {numberFiles- 1}
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  )
}
